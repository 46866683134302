<template>
  <section>
    <b-field>
      <b-datepicker
        ref="datepicker"
        expanded
        placeholder="Select a date"
        v-model="selectedDateComputed"
        :min-date="minDate"
        :max-date="maxDate"
        :inline="inline"
        :indicators="indicators"
        :position="position"
        icon="calendar"
        :icon-right="selectedDateComputed ? 'close-circle' : ''"
        icon-right-clickable
        :type="type"
        @icon-right-click="clearDate"
        @input="handleDateChange"
      >
      </b-datepicker>
      <b-button @click="$refs.datepicker.toggle()" icon-left="calendar-today" type="is-info" />
    </b-field>
  </section>
</template>

<script>
const today = new Date();

export default {
  props: {
    type: {
      type: String,
      default: 'month',
    },
    date: {
      type: Date,
    },
  },
  computed: {
    indicators() {
      return this.bars ? 'bars' : 'dots';
    },
    selectedDateComputed: {
      get() {
        return this.date ? new Date(this.date) : null;
      },
      set(value) {
        this.$emit('update:date', value);
      },
    },
  },
  data() {
    return {
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      maxDate: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
      bars: true,
      inline: false,
      position: 'is-bottom-left',
      transformedDates: [],
    };
  },
  methods: {
    handleDateChange(selectedDate) {
      this.$emit('date-selected', selectedDate);
    },
    clearDate() {
      this.$emit('date-selected', null);
    },
  },
};
</script>