<template>
  <b-carousel
    :pause-info="pauseInfo"
    :arrow-hover="arrowHover"
    :icon-prev="iconPrev"
    :icon-next="iconNext"
    :icon-size="iconSize"
    :indicator-style="indicatorStyle"
    :indicator-inside="indicatorInside"
    :indicator="indicator"
    :indicator-images="indicatorImages">
    <b-carousel-item v-for="(item, i) in items" :key="i">
      <b-image class="image" :src="item.src"></b-image>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    pauseInfo: {
      type: Boolean,
      default: false,
    },
    arrowHover: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: 'chevron-left',
    },
    iconNext: {
      type: String,
      default: 'chevron-right',
    },
    iconSize: {
      type: String,
      default: 'is-large',
    },
    indicatorStyle: {
      type: String,
      default: 'is-lines',
    },
    indicatorInside: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: Boolean,
      default: true,
    },
    indicatorImages: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
