import Layout from '@/layouts/web/index.vue';
import Home from '@/modules/web/index.vue';
import Reservations from '@/modules/web/reservations/index.vue';
import ReservationsDates from '@/modules/web/reservations/travelDateSelection.vue';
import TicketSelection from '@/modules/web/reservations/ticketTypeSelection.vue';
import NotFound from '@/modules/web/not-found.vue';

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
      },
      {
        path: '/reservaciones',
        component: Reservations,
        name: 'reservations',
      },
      {
        path: '/reservaciones/autobus',
        component: ReservationsDates,
        name: 'reservations-bus',
      },
      {
        path: '/reservaciones/tren',
        component: ReservationsDates,
        name: 'reservations-train',
      },
      {
        path: '/reservaciones/seleccion-boletos/:reservationId',
        component: TicketSelection,
        name: 'ticket-selection',
        props: true,
      },
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'NotFound'
  }
];
