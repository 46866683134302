<template>
  <div class="ticket-tipe-page py-6">
    <div class="container">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <!-- Paso 1: Selección del tipo de boleto y cantidad de pasajeros -->
        <b-step-item step="1" label="Tipo de Boleto" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Selección del Boleto</h1>
          <div class="columns is-multiline is-centered">
            <div class="column is-6 mb-6">
              <b-field label="Tipo de Boleto">
                <b-radio v-model="ticketType" native-value="oro">Oro</b-radio>
                <b-radio v-model="ticketType" native-value="plata">Plata</b-radio>
              </b-field>
              <b-field label="Cantidad de Pasajeros">
                <b-input v-model="passengerCount" type="number" min="1"></b-input>
              </b-field>
              <p><strong>Fecha seleccionada:</strong> {{ selectedDate }}</p>
              <p><strong>Hora seleccionada:</strong> {{ selectedTime }}</p>
            </div>
          </div>
        </b-step-item>

        <!-- Paso 2: Información de los pasajeros -->
        <b-step-item step="2" label="Datos de Pasajeros" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Datos de los Pasajeros</h1>
          <div class="columns is-multiline is-centered">
            <div v-for="(passenger, index) in passengers" :key="index" class="column is-4 mb-6">
              <b-field :label="'Nombre pasajero #' + (index + 1)">
                <b-input v-model="passenger.name"></b-input>
              </b-field>
              <b-field label="Apellidos">
                <b-input v-model="passenger.lastName"></b-input>
              </b-field>
              <b-field label="Correo">
                <b-input v-model="passenger.email" type="email"></b-input>
              </b-field>
              <b-field label="Teléfono">
                <b-input v-model="passenger.phone" type="tel"></b-input>
              </b-field>
              <b-field label="Tipo de Pasajero">
                <b-select v-model="passenger.type">
                  <option value="adulto">Adulto</option>
                  <option value="nino">Niño</option>
                  <option value="tercera_edad">3ra Edad</option>
                </b-select>
              </b-field>
              <b-field label="País">
                <b-input v-model="passenger.country"></b-input>
              </b-field>
              <b-field label="Estado">
                <b-input v-model="passenger.state"></b-input>
              </b-field>
              <hr />
            </div>
          </div>
        </b-step-item>

        <!-- Paso 3: Selección de Asientos -->
        <b-step-item step="3" label="Seleccionar Asientos" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Selección de Asientos</h1>
          <Seats />
        </b-step-item>

        <!-- Paso 4: Finalizar Orden -->
        <b-step-item step="4" label="Finalizar Orden" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Finalizar Orden</h1>
          <!-- Aquí se integrará el componente de finalización de pago, como PayPal -->
          <PaymentConfirmation />
        </b-step-item>

        <!-- Botones de navegación personalizados -->
        <template v-if="customNavigation" #navigation="{ previous, next }">
          <div class="buttons is-centered">
            <b-button outlined type="is-info" icon-pack="fas" icon-left="backward" :disabled="previous.disabled" @click.prevent="previous.action">
              Anterior
            </b-button>
            <b-button outlined type="is-info" icon-pack="fas" icon-right="forward" :disabled="next.disabled" @click.prevent="next.action">
              Siguiente
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import Seats from './seatSelection.vue';
import PaymentConfirmation from './paymentConfirmation.vue';

export default {
  components: {
    Seats,
    PaymentConfirmation,
  },
  data() {
    return {
      activeStep: 0,
      ticketType: '',
      passengerCount: 1,
      passengers: [
        {
          name: '',
          lastName: '',
          email: '',
          phone: '',
          type: 'adulto',
          country: '',
          state: '',
        },
      ],
      selectedDate: '2024-08-20', // Ejemplo de fecha seleccionada
      selectedTime: '11:00 AM', // Ejemplo de hora seleccionada

      // Opciones de configuración del componente de pasos
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
    };
  },
  watch: {
    passengerCount(newCount) {
      this.passengers = Array.from({ length: newCount }, () => ({
        name: '',
        lastName: '',
        email: '',
        phone: '',
        type: 'adulto',
        country: '',
        state: '',
      }));
    },
  },
  methods: {
    // Métodos para navegación entre pasos
    nextStep() {
      if (this.activeStep < 4) {
        this.activeStep++;
      }
    },
    prevStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
  },
};
</script>

<style lang="scss">
.ticket-type-selection {
  .steps {
    margin-bottom: 2rem;
  }
  .step-content {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>
