<template>
  <div class="travel-date-page py-6">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h2 class="title">{{ reservationTitle }}</h2>
        </div>
        <div class="column is-3">
          <div class="reservations-filters">
            <label class="label">Selecciona una fecha para tu viaje</label>
            <Datepicker :date="activeDate" @date-selected="handleDateSelected" />
          </div>
        </div>

        <div class="column is-9">
          <div class="reservations-schedules">
            <div class="schedule" v-for="(schedule, index) in schedules" :key="index">
              <div class="columns is-multiline">
                <div class="column is-2">
                  <div class="schedule-logo">
                    <b-image :src="schedule.logo" alt="Logo" ratio="601by235" :rounded="rounded"></b-image>
                  </div>
                </div>
                <div class="column is-3">
                  <div class="schedule-date">
                    <p class="title">Fecha</p>
                    <p class="subtitle">{{ schedule.date }}</p>
                  </div>
                </div>
                <div class="column is-2">
                  <div class="schedule-date-start">
                    <p class="title">Salida</p>
                    <p class="subtitle">{{ schedule.departure }}</p>
                  </div>
                </div>
                <div class="column is-2">
                  <div class="schedule-date-end">
                    <p class="title">Regreso</p>
                    <p class="subtitle">{{ schedule.return }}</p>
                  </div>
                </div>
                <div class="column is-3">
                  <div class="schedule-aditional">
                    <p class="title">Detalles</p>
                    <p class="subtitle">{{ schedule.details }}</p>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="schedule-chekout">
                    <div class="has-text-right">
                      <b-button tag="router-link" :to="{ name: 'ticket-selection', params: { reservationId: 1 } }" type="is-info" rounded>
                        ¡Reserva tu lugar ahora!
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@/components/common/datepicker.vue';

export default {
  name: 'ReservationsDates',
  components: {
    Datepicker,
  },
  data() {
    return {
      activeDate: null,
      schedules: [
        {
          logo: 'https://reservaciones.tequilaexpress.com.mx/themes/version2015/images/trenminisol.jpg',
          date: '23 de agosto del 2024',
          departure: '11:00 AM',
          return: '6:00 PM',
          details: '.................',
        },
        {
          logo: 'https://reservaciones.tequilaexpress.com.mx/themes/version2015/images/trenminisol.jpg',
          date: '24 de agosto del 2024',
          departure: '11:00 AM',
          return: '6:00 PM',
          details: '.................',
        },
        {
          logo: 'https://reservaciones.tequilaexpress.com.mx/themes/version2015/images/trenminisol.jpg',
          date: '30 de agosto del 2024',
          departure: '11:00 AM',
          return: '6:00 PM',
          details: '.................',
        },
      ],
    };
  },
  computed: {
    isTrain() {
      return this.$route.path.includes('tren');
    },
    reservationTitle() {
      return this.isTrain ? '¡Viaje en Tren a Tequila, Jalisco!' : '¡Viaje en Autobús a Tequila, Jalisco!';
    },
    reservationSubTitle() {
      return this.isTrain ? 'Reserva tu emocionante viaje en tren' : 'Reserva tu cómodo viaje en autobús';
    },
  },
  methods: {
    handleDateSelected(date) {
      this.activeDate = date;
      console.log(this.activeDate);
    },
  },
};
</script>

<style lang="scss">
.travel-date-page {
  .title {
    font-size: 1.2rem;
    font-weight: 900;
    color: #2c3e50;
    margin-bottom: 20px;
  }

  .schedule {
    border-bottom: 1px solid #1b5b76;
    padding: 10px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: bold;
    color: #1b5b76;
  }

  .schedule-chekout {
    margin-top: 15px;
  }
}
</style>
