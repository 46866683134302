<template>
  <div class="seat-selection">
    <div class="bus-interior">
      <div class="seats">
        <!-- Fila 1 -->
        <div class="row">
          <div v-for="(seat, index) in seats[0]" :key="index" class="seat" :class="{ available: seat.available, selected: seat.selected }">
            <input
              type="checkbox"
              :id="'seat-' + seat.id"
              :value="seat.id"
              v-model="selectedSeats"
              @change="toggleSeat(seat.id)"
              :disabled="!seat.available"
            />
            <label :for="'seat-' + seat.id">{{ seat.label }}</label>
          </div>
        </div>

        <!-- Fila 2 -->
        <div class="row">
          <div v-for="(seat, index) in seats[1]" :key="index" class="seat" :class="{ available: seat.available, selected: seat.selected }">
            <input
              type="checkbox"
              :id="'seat-' + seat.id"
              :value="seat.id"
              v-model="selectedSeats"
              @change="toggleSeat(seat.id)"
              :disabled="!seat.available"
            />
            <label :for="'seat-' + seat.id">{{ seat.label }}</label>
          </div>
        </div>

        <p>Frente ----- Pasillo  ----- Posterior</p>

        <!-- Fila 3 -->
        <div class="row">
          <div v-for="(seat, index) in seats[2]" :key="index" class="seat" :class="{ available: seat.available, selected: seat.selected }">
            <input
              type="checkbox"
              :id="'seat-' + seat.id"
              :value="seat.id"
              v-model="selectedSeats"
              @change="toggleSeat(seat.id)"
              :disabled="!seat.available"
            />
            <label :for="'seat-' + seat.id">{{ seat.label }}</label>
          </div>
        </div>

                <!-- Fila 4 -->
        <div class="row">
          <div v-for="(seat, index) in seats[3]" :key="index" class="seat" :class="{ available: seat.available, selected: seat.selected }">
            <input
              type="checkbox"
              :id="'seat-' + seat.id"
              :value="seat.id"
              v-model="selectedSeats"
              @change="toggleSeat(seat.id)"
              :disabled="!seat.available"
            />
            <label :for="'seat-' + seat.id">{{ seat.label }}</label>
          </div>
        </div>

        <!-- Añade más filas según sea necesario -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seats: [
        [
          // Fila 1
          { id: '1A', label: '1A', available: true, selected: false },
          { id: '2A', label: '2A', available: true, selected: false },
          { id: '3A', label: '3A', available: true, selected: false },
          { id: '4A', label: '4A', available: true, selected: false },
          { id: '5A', label: '5A', available: true, selected: false },
          { id: '6A', label: '6A', available: true, selected: false },
          { id: '7A', label: '7A', available: true, selected: false },
          { id: '8A', label: '8A', available: true, selected: false },
          { id: '9A', label: '9A', available: true, selected: false },
          { id: '10A', label: '10A', available: true, selected: false },
          { id: '11A', label: '11A', available: true, selected: false },
          { id: '12A', label: '12A', available: true, selected: false },
          { id: '13A', label: '13A', available: true, selected: false },
          { id: '14A', label: '14A', available: true, selected: false },
          { id: '15A', label: '15A', available: true, selected: false },
          { id: '16A', label: '16A', available: true, selected: false },
        ],
        [
          // Fila 2
          { id: '1B', label: '1B', available: true, selected: false },
          { id: '2B', label: '2B', available: true, selected: false },
          { id: '3B', label: '3B', available: true, selected: false },
          { id: '4B', label: '4B', available: true, selected: false },
          { id: '5B', label: '5B', available: true, selected: false },
          { id: '6B', label: '6B', available: true, selected: false },
          { id: '7B', label: '7B', available: true, selected: false },
          { id: '8B', label: '8B', available: true, selected: false },
          { id: '9B', label: '9B', available: true, selected: false },
          { id: '10B', label: '10B', available: true, selected: false },
          { id: '11B', label: '11B', available: true, selected: false },
          { id: '12B', label: '12B', available: true, selected: false },
          { id: '13B', label: '13B', available: true, selected: false },
          { id: '14B', label: '14B', available: true, selected: false },
          { id: '15B', label: '15B', available: true, selected: false },
          { id: '16B', label: '16B', available: true, selected: false },
        ],
        [
          // Fila 3
          { id: '1C', label: '1C', available: true, selected: false },
          { id: '2C', label: '2C', available: true, selected: false },
          { id: '3C', label: '3C', available: true, selected: false },
          { id: '4C', label: '4C', available: true, selected: false },
          { id: '5C', label: '5C', available: true, selected: false },
          { id: '6C', label: '6C', available: true, selected: false },
          { id: '7C', label: '7C', available: true, selected: false },
          { id: '8C', label: '8C', available: true, selected: false },
          { id: '9C', label: '9C', available: true, selected: false },
          { id: '10C', label: '10C', available: true, selected: false },
          { id: '11C', label: '11C', available: true, selected: false },
          { id: '12C', label: '12C', available: true, selected: false },
          { id: '13C', label: '13C', available: true, selected: false },
          { id: '14C', label: '14C', available: true, selected: false },
          { id: '15C', label: '15C', available: true, selected: false },
          { id: '16C', label: '16C', available: true, selected: false },
        ],
        [
          // Fila 3
          { id: '1D', label: '1D', available: true, selected: false },
          { id: '2D', label: '2D', available: true, selected: false },
          { id: '3D', label: '3D', available: true, selected: false },
          { id: '4D', label: '4D', available: true, selected: false },
          { id: '5D', label: '5D', available: true, selected: false },
          { id: '6D', label: '6D', available: true, selected: false },
          { id: '7D', label: '7D', available: true, selected: false },
          { id: '8D', label: '8D', available: true, selected: false },
          { id: '9D', label: '9D', available: true, selected: false },
          { id: '10D', label: '10D', available: true, selected: false },
          { id: '11D', label: '11D', available: true, selected: false },
          { id: '12D', label: '12D', available: true, selected: false },
          { id: '13D', label: '13D', available: true, selected: false },
          { id: '14D', label: '14D', available: true, selected: false },
          { id: '15D', label: '15D', available: true, selected: false },
          { id: '16D', label: '16C', available: true, selected: false },
        ],
        // Añade más filas según sea necesario
      ],
      selectedSeats: [],
    };
  },
  methods: {
    toggleSeat(seatId) {
      const seat = this.findSeatById(seatId);
      if (seat) {
        seat.selected = !seat.selected;
        if (seat.selected) {
          this.selectedSeats.push(seatId);
        } else {
          this.selectedSeats = this.selectedSeats.filter((id) => id !== seatId);
        }
      }
    },
    findSeatById(seatId) {
      for (const row of this.seats) {
        const seat = row.find((s) => s.id === seatId);
        if (seat) return seat;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.seat-selection {
  position: relative;
  width: 100%;
  height: 600px; /* Ajusta el tamaño según tus necesidades */
}

.bus-interior {
  position: relative;
  width: 100%;
  height: 100%;
}

.seats {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.seat {
  width: 30px;
  height: 30px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.seat input {
  display: none;
}

.seat label {
  cursor: pointer;
  font-size: 12px;
}

.seat.available {
  background-color: #091d61;
  color: #fff;
}

.seat.selected {
  background-color: #64bab0;
}
</style>
