import Vue from 'vue';
import Vuex from 'vuex';
import ui from './ui';
import payment from './payment';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui,
    payment,
  },
});
