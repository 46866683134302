<template>
  <header class="header">
    <div class="container">
      <div class="columns">
        <div class="column">
          <b-navbar :centered="true">
            <template #brand>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                <img src="@/assets/images/logo.png" alt="Logo" />
              </b-navbar-item>
            </template>
            <template #start>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Azul Express </b-navbar-item>
              <b-navbar-dropdown label="Recorrido | The Experience">
                <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Experiencia Diamante </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Experiencia Oro </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Experiencia Plata </b-navbar-item>
              </b-navbar-dropdown>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Fotos / Pictures </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }"> Contacto / Contact </b-navbar-item>
            </template>

            <template #end>
              <b-navbar-item tag="div">
                <div class="buttons">
                  <b-button tag="router-link" :to="{ name: 'reservations' }" type="is-info" outlined> Compra tus boletos en linea aqui </b-button>
                </div>
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>