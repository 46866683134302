import { loadStripe } from '@stripe/stripe-js';

const actions = {
  async initializeStripe({ commit }) {
    const stripeKey = 'pk_test_4xLBbh71FPvVY56Zm25I8Dnz';
    const stripeInstance = await loadStripe(stripeKey);
    commit('setStripeInstance', stripeInstance);
  },
  async createPaymentToken({ commit, state }, { cardNumber, cardOwner, addressZip }) {
    const stripe = state.stripe;
    if (!stripe) {
      throw new Error('Stripe is not initialized');
    }

    const { token, error } = await stripe.createToken(cardNumber, {
      name: cardOwner,
      address_zip: addressZip,
      address_country: 'MX',
      currency: 'MXN',
    });

    if (error) {
      throw new Error(error.message);
    } else {
      commit('setPaymentToken', token);
    }
  },
  async confirmOxxoPaymentWithIntent({ commit, state }, { client_secret, billing_details }) {
    const stripe = state.stripe;
    if (!stripe) {
      throw new Error('Stripe is not initialized');
    }

    const { error, paymentIntent } = await stripe.confirmOxxoPayment(
      client_secret,
      {
        payment_method: {
          billing_details: {
            name: billing_details.name,
            email: billing_details.email,
          },
        }
      },
      {
        handleActions: false,
      }
    );

    if (error) {
      throw new Error(error.message);
    } else {
      commit('setPaymentIntent', paymentIntent);
      return paymentIntent;
    }
  },
};

export default actions;