<template>
  <div class="footer">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-2">
          <ul>
            <li><p class="title">Menú</p></li>
            <li><a href="/">Azul Express</a></li>
            <li><a href="/">Experiencia Diamante</a></li>
            <li><a href="/">Experiencia Oro</a></li>
            <li><a href="/">Experiencia Plata</a></li>
            <li><a href="/">Fotos / Pictures</a></li>
            <li><a href="/">Contacto / Contact</a></li>
          </ul>
        </div>
        <div class="column is-3">
          <ul class="contact-links">
            <li>
              <a href="/">
                <b>Dirección</b> <br />
                Av. Vallarta # 4095, esquina Niño Obrero Guadalajara, Jalisco México
              </a>
            </li>
          </ul>
        </div>
        <div class="column is-4">
          <ul class="contact-links">
            <li>
              <a href="/"
                ><b>Teléfono</b> <br />
                0133-3880- 9013</a
              >
            </li>
            <li>
              <a href="/"
                ><b>Correo</b> <br />
                tequilaexpress@camaradecomerciogdl.mx</a
              >
            </li>
          </ul>
        </div>
        <div class="column is-3">
          <h3 class="title">Nuestras Redes</h3>
          <ul class="social-networks">
            <li>
              <a target="_blank" href="https://www.facebook.com/tequilaxpress"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/TequilaExpress_"><i class="fab fa-x-twitter"></i></a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/tequilaexpress/"><i class="fab fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>