<template>
  <div class="home-page">
    <div class="banners" v-if="banners">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12">
            <Carousel :items="banners" />
          </div>
        </div>
      </div>
    </div>

    <div class="our-services">
      <div class="container">
        <div class="our-services-logo">
          <div class="columns is-centered">
            <div class="column is-12">
              <b-image
                :src="'https://static.wixstatic.com/media/f97cdf_659182a00aad412db976e04614526e2c~mv2.png/v1/fill/w_400,h_158,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f97cdf_659182a00aad412db976e04614526e2c~mv2.png'"
              ></b-image>
            </div>
          </div>
        </div>
        <div class="our-services-list">
          <div class="columns">
            <div class="column is-4">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img
                      src="https://static.wixstatic.com/media/f97cdf_a6c57473d71041269bcab13d8f354346~mv2.png/v1/fill/w_320,h_198,al_c,lg_1,q_85,enc_auto/f97cdf_a6c57473d71041269bcab13d8f354346~mv2.png"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-48x48">
                        <img
                          src="https://static.wixstatic.com/media/f97cdf_a6c57473d71041269bcab13d8f354346~mv2.png/v1/fill/w_320,h_198,al_c,lg_1,q_85,enc_auto/f97cdf_a6c57473d71041269bcab13d8f354346~mv2.png"
                          alt="Placeholder image"
                        />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="title is-4">Tour Diamante</p>
                    </div>
                  </div>

                  <div class="content">
                    Descubre el mundo del tequila en su máxima expresión con el Tour Diamante. Disfruta de un recorrido completo que incluye
                    transporte, visitas a campos agaveros, bodegas de añejamiento y la fábrica. Recibe una cálida bienvenida con Cantarito, una
                    botella premium y una parrillada acompañada de música en vivo. Además, aprovecha la oportunidad de comprar tequila a precio de
                    fábrica y explorar el centro de Tequila. Una experiencia única y deliciosa que te cautivará.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img
                      src="https://static.wixstatic.com/media/f97cdf_c63bda2ed1904172912acc525b8a2202~mv2.png/v1/fill/w_320,h_205,al_c,lg_1,q_85,enc_auto/f97cdf_c63bda2ed1904172912acc525b8a2202~mv2.png"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-48x48">
                        <img
                          src="https://static.wixstatic.com/media/f97cdf_c63bda2ed1904172912acc525b8a2202~mv2.png/v1/fill/w_320,h_205,al_c,lg_1,q_85,enc_auto/f97cdf_c63bda2ed1904172912acc525b8a2202~mv2.png"
                          alt="Placeholder image"
                        />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="title is-4">Tour Oro</p>
                    </div>
                  </div>

                  <div class="content">
                    Explora la excelencia del tequila con el Tour Oro. Este completo recorrido incluye transporte en autobús con bebidas a bordo,
                    seguro de viaje y guía certificado. Sumérgete en los campos agaveros, las bodegas y la fábrica de Casa Sauza. Disfruta de un
                    refrescante Cantarito, auténtica comida mexicana, música en vivo y una visita al centro de Tequila. Una experiencia incomparable
                    que no olvidarás.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img
                      src="https://static.wixstatic.com/media/f97cdf_45ad047d4ebc4625b859c96ea5f249d4~mv2.png/v1/fill/w_264,h_173,al_c,lg_1,q_85,enc_auto/f97cdf_45ad047d4ebc4625b859c96ea5f249d4~mv2.png"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-48x48">
                        <img
                          src="https://static.wixstatic.com/media/f97cdf_45ad047d4ebc4625b859c96ea5f249d4~mv2.png/v1/fill/w_264,h_173,al_c,lg_1,q_85,enc_auto/f97cdf_45ad047d4ebc4625b859c96ea5f249d4~mv2.png"
                          alt="Placeholder image"
                        />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="title is-4">Tour Plata</p>
                    </div>
                  </div>

                  <div class="content">
                    Embárcate en una emocionante aventura con el Tour Plata. Incluye transporte en autobús con bebidas a bordo, guía certificado y
                    visitas a campos agaveros y bodegas de Casa Sauza. Descubre el auténtico sabor del tequila con precios de fábrica y una
                    experiencia culinaria especial en la Cueva de Don Cenobio. Completa tu día con una visita guiada por el centro de Tequila. Una
                    experiencia imperdible para los amantes del tequila.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tripadvisor">
      <div class="container">
        <div class="columns is-centered is-multiline">
          <div class="column is-12">
            <h2 class="title">¡Déjanos tu Opinión en TripAdvisor!</h2>
          </div>
          <div class="column is-6">
            <iframe
              class="wuksD5"
              title="Embedded Content"
              name="htmlComp-iframe"
              data-src=""
              src="https://www-tequilaexpress-mx.filesusr.com/html/f97cdf_d6925513e8ed2aa84244ff6904726a35.html"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="youtube-videos">
      <div class="container">
        <div class="columns is-centered is-multiline">
          <div class="column is-12">
            <h2 class="title">Experiencia Tequila Express</h2>
          </div>
          <div class="column is-8">
            <iframe
              src="https://www.youtube.com/embed/I-mZdPuU7ug?wmode=transparent&amp;autoplay=0&amp;mute=0&amp;theme=dark&amp;controls=1&amp;autohide=1&amp;loop=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/common/carousel.vue';

export default {
  name: 'Web',
  components: {
    Carousel,
  },
  data() {
    return {
      banners: [
        {
          id: '1',
          src: 'https://static.wixstatic.com/media/f97cdf_24ec3f34bdba45cd9dec483967a72524~mv2.jpg/v1/fill/w_980,h_390,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f97cdf_24ec3f34bdba45cd9dec483967a72524~mv2.jpg',
        },
        {
          id: '2',
          src: 'https://static.wixstatic.com/media/f97cdf_855131c2c1ba4d899054c2785e7f9d1d~mv2.jpg/v1/fill/w_980,h_390,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f97cdf_855131c2c1ba4d899054c2785e7f9d1d~mv2.jpg',
        },
        {
          id: '3',
          src: 'https://static.wixstatic.com/media/f97cdf_63a9a35cef8640368d1d13d0965e256f~mv2.jpg/v1/fill/w_979,h_389,al_c,q_85,enc_auto/f97cdf_63a9a35cef8640368d1d13d0965e256f~mv2.jpg',
        },
      ],
    };
  },
  computed: {},
};
</script>